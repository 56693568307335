import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  matchPath,
} from "react-router-dom";
import { Container } from "@material-ui/core";
import { useIntl } from "react-intl";
import DocumentTitle from "react-document-title";

import Attractor from "./Attractor.js";
import Notifications from "../components/Notifications.js";
import WebsiteHeader from "../components/WebsiteHeader.js";

import ConfigContext from "../config.js";

import { makeStyles } from "@material-ui/core/styles";
const HEADER_HEIGHT = 117;
const HEADER_HEIGHT_MD = 92;
const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    minHeight: `calc(100vh - ${HEADER_HEIGHT}px + 1px)`,
    [theme.breakpoints.up("md")]: {
      minHeight: `calc(100vh - ${HEADER_HEIGHT_MD}px + 1px)`,
    },
  },
  pageContainer: { width: "100%", height: "100%", overflow: "hidden" },
}));

const Website = () => {
  const { ROUTES } = useContext(ConfigContext);
  const classes = useStyles();
  const location = useLocation();
  const intl = useIntl();

  return (
    <Router>
      <Notifications />
      <Switch>
        {Object.values(ROUTES).map(({ path, Component, title }) => (
          <Route
            key={location.pathname}
            path={path}
            exact
            strict
            sensitive
            render={(routeProps) => {
              const matchQuestion = matchPath(path, {
                path: ROUTES.QUESTION.path,
                strict: true,
                exact: true,
              });
              const matchContribute = matchPath(path, {
                path: ROUTES.CONTRIBUTE.path,
                strict: true,
                exact: true,
              });
              const matchAsk = matchPath(path, {
                path: ROUTES.ASK.path,
                strict: true,
                exact: true,
              });

              const containerProps = matchQuestion
                ? {
                    style: {
                      maxWidth: "none",
                      justifyContent: "center",
                    },
                  }
                : matchContribute || matchAsk
                ? { style: { justifyContent: "center" } }
                : {};

              return (
                <DocumentTitle
                  title={`${intl.messages["app_title"]} - ${intl.messages[title]} | ${window.location.href}`}
                >
                  <div className={classes.pageContainer}>
                    <WebsiteHeader />
                    <div className={classes.container} {...containerProps}>
                      <Component {...routeProps} />
                    </div>
                    <Attractor {...routeProps} in={matchQuestion} />
                  </div>
                </DocumentTitle>
              );
            }}
          />
        ))}
        <Route
          render={() => (
            <Container>
              <div>No match</div>
            </Container>
          )}
        />
      </Switch>
    </Router>
  );
};

export default Website;
